import React, { useState } from "react";
import "./Login.css";
import { Helmet } from "react-helmet";
import logo from "../../../assets/logo.png";
import bg from "../../../assets/login_bg.png";
import border4 from "../../../assets/login_4.png";
import border1 from "../../../assets/login_1.png";
import border2 from "../../../assets/login_2.png";
import border3 from "../../../assets/login_3.png";
import { useLocation } from "react-router-dom";
import axios from "axios";
import QRCode from "react-qr-code";
import Verify from "./Verify";

const SecretQuestion = () => {
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [secret, setSecret] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const [showQRCode, setShowQRCode] = useState(false);
  const [showSecret, setShowSecret] = useState(true);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      selectedQuestion: selectedQuestion,
      answer: answer,
    };
    try {
      const response = await axios.post("/api/qrcode", payload);
      setSecret(response.data.secret);
      setShowSecret(false);
      setShowQRCode(true);
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message);
      }
    }
  };

  const accountName = "mathotop";
  const uri = `otpauth://totp/${encodeURIComponent(
    accountName
  )}?secret=${secret}&issuer=${encodeURIComponent(accountName)}`;

  return (
    <>
      <Helmet>
        <title>Admin | Login to your account</title>{" "}
      </Helmet>
      {showSecret && (
        <div className="login_form_wrapper">
          <div
            className="login_form_div none"
            style={{ flexDirection: "row", gap: "0", height: "100%" }}
          >
            {" "}
            <img src={bg} alt="" className="login_bg" />{" "}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0",
                height: "100%",
              }}
            >
              <img src={border1} alt="border" style={{ height: "25%" }} />
              <img src={border2} alt="border" style={{ height: "25%" }} />
              <img src={border3} alt="border" style={{ height: "25%" }} />
              <img src={border4} alt="border" style={{ height: "25%" }} />
            </div>
          </div>
          <div className="login_form_div">
            {" "}
            <img src={logo} alt="" className="logo_login" />{" "}
            <form onSubmit={handleSubmit}>
              <div className="login_text_field">
                <h6>Choisir la question de sécurité</h6>
                <select
                  name="securityQuestion"
                  id="securityQuestion"
                  value={selectedQuestion}
                  onChange={(e) => setSelectedQuestion(e.target.value)}
                >
                  {" "}
                  <option value="">Sélectionnez une question...</option>
                  <option value="What was the name of your first pet?">
                    What was the name of your first pet?
                  </option>
                  <option value="What is your mother’s maiden name?">What is your mother’s maiden name?</option>
                  <option value="What was the make and model of your first car?">
                    What was the make and model of your first car?
                  </option>
                  <option value="What was the name of your elementary school?">
                    What was the name of your elementary school?
                  </option>
                  <option value="In what city were you born?">In what city were you born?</option>
                  <option value="What is your favorite book?">What is your favorite book?</option>
                  <option value="What is your favorite food?">What is your favorite food?</option>
                  <option value="What was the name of your first employer?">
                    What was the name of your first employer?
                  </option>
                  <option value="What is your father's middle name?">What is your father's middle name?</option>
                  <option value="Where did you go on your first vacation?">
                    Where did you go on your first vacation?
                  </option>
                  <option value="What was the name of your best childhood friend?">
                    What was the name of your best childhood friend?
                  </option>
                  <option value="What is your favorite movie?">What is your favorite movie?</option>
                  <option value="What was your childhood nickname?">What was your childhood nickname?</option>
                  <option value="What was the name of the street you grew up on?">
                    What was the name of the street you grew up on?
                  </option>
                  <option value="What is your favorite sports team?">
                    What is your favorite sports team?
                  </option>
                  <option value="What was the name of your first teacher?">
                    What was the name of your first teacher?
                  </option>
                  <option value="What is your favorite hobby?">What is your favorite hobby?</option>
                  <option value="What is your oldest sibling’s middle name?">
                    What is your oldest sibling’s middle name?
                  </option>
                  <option value="What was the name of the town where you went to high school?">
                    What was the name of the town where you went to high school?
                  </option>
                  <option value="What was your first job?">What was your first job?</option>
                </select>
              </div>
              <div className="login_text_field">
                <h6>Réponse</h6>
                <input
                  type="text"
                  name="answer"
                  id="answer"
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  placeholder="..."
                  required
                />
              </div>
              {error && <div className="error">{error}</div>}

              <div className="login_button">
                <button type="submit">Envoyer</button>
              </div>
            </form>
          </div>
        </div>
      )}

      {showQRCode && <Verify email={email} secret={secret} />}
    </>
  );
};

export default SecretQuestion;
